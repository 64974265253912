const adSetList = [{ id: 1, name: "ihor test", link: "https://www.gomarketplan.com?utm_source=source&utm_campaign=name&utm_content=content" }];

function App() {
  return (
    <>
      <div
        style={{
          margin: "30px auto",
          width: "600px",
          display: "flex",
          gap: "20px",
        }}
      >
        {adSetList.map((adSet) => (
          <button
            key={adSet?.id}
            onClick={() => window.open(adSet?.link, "_blank")}
          >
            {adSet?.name}
          </button>
        ))}
      </div>
    </>
  );
}

export default App;
